<template>
    <div class="w-full h-full pt-5">
        <div class="flex">
            <back-button
                label="Back"
                @onClick="$router.back()"
                variant="secondary"
                class="mr-4"
            />
            <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />
            <h1 class="text-xl  text-left font-extrabold ml-4 mt-3">
                Off-Cycle Pay
            </h1>
            <div class="w-7/12 mt-4 ml-8">
                <Breadcrumb :items="breadcrumbs" />
            </div>
        </div>
        <div style="height:100%" v-if="loading">
            <loader size="xxs" :loader-image="false" />
        </div>
      <div v-else>
        <div class="px-3">
          <card class="p-5 mt-6 ">
            <div class="w-full mb-4 flex flex-col gap-5 border border-dashed border-romanSilver rounded p-3">
              <div class="w-full flex justify-between items-center">
                <TrendToolTip :is-trend-tool-tip="isTrendToolTip"
                  @on-enter=" isTrendToolTip = true"
                  @on-leave=" isTrendToolTip = false"
                />
                <p @click="isTrend = !isTrend"
                  class="w-1/3 cursor-pointer text-right text-blueCrayola font-semibold text-sm"
                >
                    {{isTrend ? `Collapse Trend` : `Expand Trend`}}
                </p>
              </div>
              <div v-if="isTrend" class="gridContainer">
                <TrendState
                  type="Last month"
                  :amount=" payTrend ?  payTrend.previousPayrun.grossPay : 0"
                  :net-amount=" payTrend ?  payTrend.previousPayrun.netPay : 0"
                  :emp-count=" payTrend ?  payTrend.previousPayrun.empCount : 0"
                  icon-name="blue-gross-icon"
                />
  
                <TrendState
                  type="Current month"
                  :amount=" payTrend ?  payTrend.currentPayrun.grossPay : 0"
                  :net-amount=" payTrend ?  payTrend.currentPayrun.netPay : 0"
                  :emp-count=" payTrend ?  payTrend.currentPayrun.empCount : 0"
                  icon-name="yellow-gross-icon"
                />
  
                <TrendState
                  type="Variance"
                  :amount=" payTrend ?  payTrend.variance.grossPay : 0"
                  :net-amount=" payTrend ?  payTrend.variance.netPay : 0"
                  :emp-count=" payTrend ?  payTrend.variance.empCount : 0"
                  icon-name="green-gross-icon"
                  :trend="growthTrend"
                />
              </div>
            </div>
            <div class="w-full h-auto p-3 rounded" style="border: 1px dashed #878E99">
              <p class="font-bold text-lg mb-4 ">Pay Run Information</p>
              <div class="w-full flex flex-wrap justify-start items-center gap-2">
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    Payrun Type
                  </h6>
                  <h6 class="font-semibold text-sm text-blueCrayola uppercase">
                    {{ type }}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                     Payment frequency:
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{
                       payFrequency && payFrequency === "twice_monthly"
                        ? "Twice Monthly"
                        : payFrequency === "weekly"
                        ? "Four Times Monthly"
                        : payFrequency === "monthly" ? payFrequency : "---"
                    }}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    Payment Schedule:
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ splitPosition ? `${$getOrdinal(splitPosition)} Payment` : "NA" }}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    pay date
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ payDate ? payDate : "---"}}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    initiated by
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ initiatedBy ? initiatedBy : "---"}}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    approved by
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ approvedBy ? approvedBy : "---"}}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    paid by
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ paidBy ? paidBy : "---"}}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    pay reference #
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    CL16282637272
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    total employees
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ selectedEmployees }} of {{ employeeTotalCount }}
                  </h6>
                </card>
                <card class="p-3 w-204">
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    success rate
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ successRate ? successRate : "---"}}
                  </h6>
                </card>
                <card class="p-3 w-204 relative">
                  <div @click="isTotalGross = true"
                    class="w-auto h-auto absolute top-3 right-3 cursor-pointer"
                  >
                    <icon
                      icon-name="icon-eye"
                      class-name="text-blueCrayola"
                      size="xs"
                    />
                  </div>
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    total Gross pay
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                      {{ convertToCurrency(employeeGrossPay) }}
                  </h6>
                </card>
                <card class="p-3 w-204 relative">
                  <div @click="isTotalGross = true"
                    class="w-auto h-auto absolute top-3 right-3 cursor-pointer"
                  >
                    <icon
                      icon-name="icon-eye"
                      class-name="text-blueCrayola"
                      size="xs"
                    />
                  </div>
                  <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    total net pay
                  </h6>
                  <h6 class="font-semibold text-sm text-jet uppercase">
                      {{ convertToCurrency(totalNetPay) }}
                  </h6>
                </card>
              </div>
            </div>
            <div class="w-full flex justify-end my-5">
              <div class="flex">
                <Button
                  :background="appearance.buttonColor || defaultBTNColor"
                  class="text-white buttons flex mr-5"
                  @click="save('Under Review')"
                  :disabled="disableBtn"
                >
                  Process
                </Button>
                <Button
                  class="bg-white ml-4 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                  style-name="width:inherit;"
                  options
                  :option-list="['Save as Draft', 'Cancel']"
                  @click="buttonOptionSelected($event)"
                >
                  Select an Option
                </Button>
              </div>
            </div>
            <div v-feature-request class="flex justify-start items-center gap-2 my-10">
              <checkbox
                checkbox-size="height:16px; width:16px; margin-top: 0px; cursor: pointer"
                @change="handleProcessAllEmployees"
                v-model="processAllEmployees"
              />
              <p class="font-semibold text-sm text-blueCrayola">
                Select to process all 200 employees
              </p>
            </div>
            <div class="flex flex-col w-full mb-8">
              <div
                class="
            flex
            w-full
            rounded
            text-blue text-sm
            font-bold
            px-4
            py-3
          "
                style="
          background: rgba(244, 213, 176, 0.15); border: 1px solid #E99323;"
                role="alert"
              >
                <Icon icon-name="tooltip" size="l" class="w-8 h-8 mr-2" />
                <div class="flex flex-col tracking-wide ml-2">
                  <p class="mb-2">
                    Your employees will appear here when:
                  </p>
                  <p class="mb-1">
                    o Employment and payroll status is active.
                  </p>
                  <p class="mb-1">
                    o Employees bank information are updated.
                  </p>
                  <p class="mb-1">
                    o Organisation pay settings and pay schedule are setup.
                  </p>
                  <p class="mb-1">
                    o Employees annual gross earnings are updated.
                  </p>
                  <p class="mb-1">
                    o Previous month's pay run is approved/paid or effective
                    payroll date is within the current month.
                  </p>
                  <p class="mb-1">
                    o In the case of split payments, split arrears must be
                    approved or paid.
                  </p>
                </div>
              </div>
            </div>
  
            <card class="p-4 mt-6">
              <card-footer
                reloadcard
                printcard
                @reload="reload()"
                @actionModal="handleModal()"
                @sortType="handleSort($event)"
                @option="handleOptionSelect($event)"
                @searchResult="handleSearch($event)"
              >
              </card-footer>
            </card>
          </card>
        </div>
        <div>
          <div class="px-3"></div>
          <div class="px-3">
            <card class="p-1 mt-6 mb-8">
              <sTable
                :headers="headers"
                :items="filteredTableData"
                style="width:100%"
                class="w-full"
                :has-checkbox="true"
                aria-label="payRun table"
                :has-number="false"
                :loading="tableLoading"
                @rowSelected="handleRowSelected($event)"
                selection-key="userId"
                :pagination-list="metaData"
                @page="handlePage($event)"
                page-sync
                @itemsPerPage="handleItemsPerPage($event)"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.photo" class="flex items-center gap-3">
                    <div v-if="isPresent" class="rounded-full sideDot1 "></div>
                    <div v-else class="rounded-full sideDot2 "></div>
                    <img
                      class=""
                      :src="item.data.photo"
                      v-if="item.data.photo"
                      alt="photo"
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 35px; width: 35px; border-radius: 5px;"
                      class="text-blueCrayola border text-center font-semibold pt-2"
                      v-else
                    >
                      {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                    </div>
                  </div>
                  <div v-else-if="item.fname">
                    <span class="tw-font-bold" style="color: #525252"
                      >{{ item.data.fname }} {{ item.data.lname }}</span
                    >
                    <p class="uppercase text-xs text-romanSilver">
                      {{
                        item.data.orgFunction ? item.data.orgFunction.name : ""
                      }}
                    </p>
                  </div>
                  <span v-else-if="item.regularEarnings" class="tw-text-center">
                    {{
                      !item.data.regularEarnings
                        ? "Nil"
                        : convertToCurrency(item.data.regularEarnings)
                    }}
                  </span>
                  <span v-else-if="item.additions" class="flex text-center">
                    <Menu
                      right
                      top="-300"
                      margin="46"
                      class="p-0"
                      v-if="item.data.additions"
                    >
                      <template v-slot:title>
                        <div class="underline text-blueCrayola">
                          {{ convertToCurrency(item.data.additions) }}
                        </div>
                      </template>
                      <div style="width: 250px; padding: 10px 5px">
                        <div
                          class="flex flex-col py-2 px-3"
                          v-for="(additions, index) in item.data
                            .monthlyAdditionsPayItems"
                          :key="index"
                        >
                          <p class="pt-1 flex flex-grow text-sm font-semibold">
                            {{ additions.name }}
                          </p>
                          <p class="pt-1 flex text-xs text-romanSilver">
                            {{ convertToCurrency(additions.amount) }}
                          </p>
                        </div>
                      </div>
                    </Menu>
                    <span v-else>
                      {{ convertToCurrency(0) }}
                    </span>
                    <span>
                      <Icon
                        class-name="text-flame cursor-pointer ml-4 mt-0 self-center"
                        size="xms"
                        @click.native="handleClick('addition', item.data.userId)"
                        icon-name="addition"
                      />
                    </span>
                    <span v-if="item.data.additions > 1">
                      <Icon
                        class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                        size="xms"
                        @click.native="handleEdit('addition', item.data.userId)"
                        icon-name="edit"
                      />
                    </span>
                  </span>
                  <span v-else-if="item.deductions" class="flex text-center">
                    <Menu
                      right
                      top="-300"
                      margin="46"
                      class="p-0"
                      v-if="item.data.deductions"
                    >
                      <template v-slot:title>
                        <div class="underline text-blueCrayola">
                          {{ convertToCurrency(item.data.deductions) }}
                        </div>
                      </template>
                      <div style="width: 250px; padding: 10px 5px">
                        <div
                          class="flex flex-col py-2 px-3"
                          v-for="(deduction, index) in item.data
                            .monthlyDeductionsPayItems"
                          :key="index"
                        >
                          <p class="pt-1 flex flex-grow text-sm font-semibold">
                            {{ deduction.name }}
                          </p>
                          <p class="pt-1 flex text-xs text-romanSilver">
                            {{ convertToCurrency(deduction.amount) }}
                          </p>
                        </div>
                      </div>
                    </Menu>
                    <span v-else>
                      {{ convertToCurrency(0) }}
                    </span>
                    <span>
                      <Icon
                        class-name="text-flame cursor-pointer ml-4 self-center"
                        size="xms"
                        @click.native="handleClick('deduction', item.data.userId)"
                        icon-name="addition"
                      />
                    </span>
                    <span v-if="item.data.deductions > 1">
                      <Icon
                        class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                        size="xms"
                        @click.native="handleEdit('deduction', item.data.userId)"
                        icon-name="edit"
                      />
                    </span>
                  </span>
                  <span v-else-if="item.paye" class="tw-text-center">
                    {{ item.data.paye ? convertToCurrency(item.data.paye) : 0 }}
                  </span>
                  <span v-else-if="item.reliefAllowance" class="tw-ml-6">
                    <c-text
                      class="tw--mt-6"
                      style="width: 131px"
                      type="number"
                      placeholder="Enter amount"
                      min="0"
                      @input="
                        checkReimbursement({
                          id: item.data.id,
                          value: item.data.reimbursements
                        })
                      "
                      v-model="item.data.reimbursements"
                    />
                  </span>
                  <span v-else-if="item.netPay" class="grosspay">
                    {{ convertToCurrency(item.data.netPay) }}
                  </span>
                </template>
              </sTable>
            </card>
          </div>
        </div>
      </div>
      <ModalBar
        v-if="setModal"
        @submit="submit"
        @close="resetModal()"
        submit="Apply"
        button-class="bg-dynamicBackBtn text-white"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <div class="text-darkPurple flex-grow">
              Filters
            </div>
            <div class="flex">
              <Icon
                class-name="text-darkPurple cursor-pointer mx-auto self-center"
                size="xs"
                icon-name="undo"
              />
            </div>
          </div>
        </template>
        <div class="w-full flex flex-col">
          <SearchInput
            placeholder="First, Last Name"
            v-model="employeeSearchInput"
            @input="handleSearch($event)"
            class="search"
            style="width : 100%"
          />
        </div>
        <div
          class="w-full mt-4 border border-dashed py-2 px-3"
          style="border-color: #878E99;"
        >
          <div class="mt-1 w-full flex">
            <p
              class="uppercase text-romanSilver font-semibold"
              style="font-size: 10px;"
            >
              Filter By :
            </p>
            <p class="font-semibold text-sm text-flame -mt-1 ml-1">
              Pay Run Type
            </p>
          </div>
          <div class="w-full border border-solid mt-2" />
          <div class="w-full mt-1 flex flex-col">
            <radio-button
              :options="categoryArray"
              col-span="col-span-12"
              class="m-4 text-darkPurple"
              row-gap="gap-y-2"
              v-model="payrunType"
              @change="resetRunby(payrunType)"
            />
          </div>
        </div>
        <div
          class="w-full mt-4 border border-dashed py-2 px-3"
          style="border-color: #878E99;"
        >
          <div class="mt-1 w-full flex">
            <p
              class="uppercase text-romanSilver font-semibold"
              style="font-size: 10px;"
            >
              Filter By :
            </p>
            <p class="font-semibold text-sm text-flame -mt-1 ml-1">
              Location(s)
            </p>
          </div>
          <div class="w-full border border-solid mt-2" />
          <div class="w-full mt-1 flex flex-col">
            <c-select
              placeholder="--Select--"
              :options="locationArray"
              variant="w-full"
              class="mt-3 w-1/2"
            />
          </div>
        </div>
        <div
          class="w-full mt-4 border border-dashed py-2 px-3"
          style="border-color: #878E99;"
        >
          <div class="mt-1 w-full flex">
            <p
              class="uppercase text-romanSilver font-semibold"
              style="font-size: 10px;"
            >
              Filter By :
            </p>
            <p class="font-semibold text-sm text-flame -mt-1 ml-1">
              Level(s)
            </p>
          </div>
          <div class="w-full border border-solid mt-2" style="height: 1px;" />
          <div class="w-full mt-1 flex flex-col">
            <c-select
              placeholder="--Select--"
              :options="levelArray"
              variant="w-full"
              class="mt-3 w-1/2"
            />
          </div>
        </div>
        <div
          class="w-full mt-4 border border-dashed py-2 px-3"
          style="border-color: #878E99;"
        >
          <div class="mt-1 w-full flex">
            <p
              class="uppercase text-romanSilver font-semibold"
              style="font-size: 10px;"
            >
              Filter By :
            </p>
            <p class="font-semibold text-sm text-flame -mt-1 ml-1">
              Function(s)
            </p>
          </div>
          <div class="w-full border border-solid mt-2" />
          <div class="w-full mt-1 flex flex-col">
            <c-select
              placeholder="--Select--"
              :options="functionArray"
              variant="w-full"
              class="mt-3 w-1/2"
            />
          </div>
        </div>
        <div
          class="w-full mt-4 border border-dashed py-2 px-3"
          style="border-color: #878E99;"
        >
          <div class="mt-1 w-full flex">
            <p
              class="uppercase text-romanSilver font-semibold"
              style="font-size: 10px;"
            >
              Filter By :
            </p>
            <p class="font-semibold text-sm text-flame -mt-1 ml-1">
              Designation(s)
            </p>
          </div>
          <div class="w-full border border-solid mt-2" />
          <div class="w-full mt-1 flex flex-col">
            <c-select
              placeholder="--Select--"
              :options="employmentType"
              variant="w-full"
              class="mt-3 w-1/2"
            />
          </div>
        </div>
      </ModalBar>
       <ViewTotalGross v-if="isTotalGross"
        :total-gross="employeeGrossPay"
         :total-net="totalNetPay"
          :total-org-pension="orgPension"
           :total-paye="paye"
            :total-pension="employeePension"
             :total-nhf="nhfAmount"
        @close="handleCloseTotalGross"
      />
      <side-modal
        :open-modal="openModal"
        :modal-type="modalType"
        :user-id="userId"
        :payItemSource="type"
        @close="handleClose"
      />
      <edit-side-modal
        :open-modal="openEditModal"
        :modal-type="modalType"
        :user-id="userId"
        :payItemSource="type"
        @close="handleEditClose"
      />
    </div>
  </template>
  
  <script>
  /* eslint-disable array-callback-return */
  /* eslint-disable consistent-return */
  /* eslint-disable no-nested-ternary */
  import { mapState } from "vuex";
  import lodash from "lodash/cloneDeep";
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import CSelect from "@scelloo/cloudenly-ui/src/components/select";
  import CText from "@scelloo/cloudenly-ui/src/components/text";
  import STable from "@scelloo/cloudenly-ui/src/components/table";
  import Card from "@/components/Card";
  import Button from "@/components/Button";
  import Menu from "@/components/Menu";
  import CardFooter from "@/components/CardFooter";
  import RadioButton from "@/components/RadioButton";
  import ModalBar from "@/components/ModalBar";
  import Checkbox from "@/components/Checkbox";
  
  export default {
    components: {
      Breadcrumb,
      BackButton,
      Card,
      CSelect,
      CardFooter,
      SearchInput,
      CText,
      Menu,
      RadioButton,
      Button,
      STable,
      ModalBar,
      Checkbox,
      SideModal: () => import("../SideModal"),
      EditSideModal: () => import("../EditSideModal.vue"),
      TrendState: () => import("@/components/TrendState"),
      ViewTotalGross: () => import("@/components/ViewTotalGross"),
      TrendToolTip: () => import("@/components/TrendToolTip"),
    },
    data() {
      return {
        nameOnPayslip:'',
        payOffCycleId: '',
        payStartDay: '',
        type: "",
        isTrendToolTip: false,
        isTrend: false,
        isTotalGross: false,
        approvedBy: "",
        paidBy: "",
        successRate: "",
        payDate: "",
        employeeSearchInput : "",
        processAllEmployees: false,
        disableBtn: false,
        metaData: {},
        itemsPerPage: 50,
        numberOfPage: 1,
        tableLoading: false,
        hierarchy: [],
        openModal: false,
        openEditModal: false,
        payrunTrend: null,
        employeeGrossPay: 0,
        initiatedBy: "",
        selectedEmployees: 0,
        modalType: "",
        userId: "",
        isPresent: true,
        payrunType: "",
        selectedCategory: "",
        categoryArray: [
          {
            name: "Regular",
            value: "Regular",
            radioName: "job"
          },
          {
            name: "Off Cycle",
            value: "Off Cycle",
            radioName: "job"
          },
          {
            name: "Terminal Pay",
            value: "Terminal Pay",
            radioName: "job"
          }
        ],
        additionalItems: [],
        month: [],
        year: [],
        employeeAddition: [],
        amount: "",
        payCycleMonth: "",
        payCycleYear: "",
        reimbursement: "",
        variableMonth: "",
        employeeSearch: false,
        advancedSearch: false,
        netPay: [],
        payType: "",
        payFrequency: "",
        splitPosition: "",
        totalEmployees: "",
        totalNetPay: 0,
        optimaUsers: [],
        paycycleSummary: [],
        employees: {},
        radioValue: "",
        allButton: "all",
        checkboxes: [],
        selected: [],
        allOptimaUsers: "",
        AVMonth: "",
        loading: true,
        tableData: [],
        item: [],
        setModal: false,
        currentPayrunData:{},
        trendEmployees: 0,
        headers: [
            { title: "", value: "photo"},
            { title: "Employee", value: "fname" },
            { title: "Payment Due", value: "regularEarnings" },
            { title: "Additions", value: "additions" },
            { title: "Deductions", value: "deductions" },
            { title: "PAYE", value: "paye" },
            { title: "Net Pay", value: "netPay" },
        ],
        page: 1,
        pageCount: 0,
        runByRadioButton: [
          { name: "All", id: "all" },
          { name: "Location", id: "location" },
          { name: "Function", id: "function" },
          { name: "Employment Type", id: "employmentType" },
          { name: "Level", id: "level" }
        ],
        employmentType: [
          { name: "Full-time", id: "Full-time" },
          { name: "Part-time", id: "Part-time" },
          { name: "Intern", id: "Intern" },
          { name: "Company Temp", id: "Company-paid Temp" },
          { name: "Contractor", id: "Independent Contractor" },
          { name: "Agency Temp", id: "Agency-paid Temp" },
          { name: "Vendor Employee", id: "Vendor Employee" },
          { name: "Volunteer", id: "Volunteer" }
        ],
        runByCheckbox: [],
  
        paymentType: [
          { name: "Regular Pay", id: "regularPay" },
          { name: "13 Month", id: "13thMonth" }
        ],
        selectedPaymentType: "13thMonth",
        drawerVisible: false,
        selitems: [],
        locationArray: [],
        functionArray: [],
        employmentArray: [],
        growthTrend: null,
        orgPension: 0,
        paye: 0,
        employeePension: 0,
        nhfAmount: 0,
        levelArray: [],
        secondaryCategory: "",
        secondaryCategoryArray: "",
      };
    },
    computed:{
      employeeTotalCount(){
        const count = this.currentPayrunData?.empCount || 0
        return this.totalEmployees + count
      },
      filteredTableData()  {
        if(!this.employeeSearchInput ){
          return this.tableData
        }
          const filteredData = this.tableData.filter(item => item.fname.toLowerCase().includes(this.employeeSearchInput.toLowerCase()) || item.lname.toLowerCase().includes(this.employeeSearchInput.toLowerCase()) || item.orgFunction?.name.toLowerCase().includes(this.employeeSearchInput.toLowerCase()));
        return filteredData
  
      },
      ...mapState({
        defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
        appearance: (state) => state.orgAppearance.orgAppearanceData,
      }),
      breadcrumbs(){
        return [
          {
            disabled: false,
            text: "Payroll",
            href: "payroll",
            id: "Payroll",
          },
          {
            disabled: false,
            text: "Payrun",
            href: "payrun",
            id: "Payrun",
          },
          {
            disabled: false,
            text: `Off-Cycle Pay`,
            href: "OffCyclePay",
            id: "OffCyclePay",
          },
          {
            disabled: false,
            text: `${this.nameOnPayslip}`,
            id: `${this.nameOnPayslip}`,
          },
        ];
      },
      payTrend(){
        return this.payrunTrend
      }
    },
    watch: {
      selectedCategory(value) {
        if (value === "all") {
          this.secondaryCategory = false;
        } else if (value === "location") {
          this.secondaryCategory = "Location(s)";
          this.secondaryCategoryArray = this.locationArray;
        } else if (value === "function") {
          this.secondaryCategory = "Function(s)";
          this.secondaryCategoryArray = this.functionArray;
        } else if (value === "employmentType") {
          this.secondaryCategory = "Employment Type(s)";
          this.secondaryCategoryArray = this.employmentType;
        } else if (value === "level") {
          this.secondaryCategory = "Level(s)";
          this.secondaryCategoryArray = this.levelArray;
        }
      }
    },
  
    methods: {
      handleRowSelected(value) {
        // Assign selected rows and reset values
        this.checkboxes = value;
        this.selectedEmployees = value.length;
        this.trendEmployees = value.length

        // Use reduce to calculate totalNetPay and employeeGrossPay
        const total =
          this.checkboxes.reduce((acc, element) => {
          if (element.netPay) {
            acc.totalNetPay += element.netPay;
            acc.employeeGrossPay += element.grossEarnings;
            acc.orgPension += element.orgPension;
            acc.paye += element.paye;
            acc.employeePension += element.employeePension;
            acc.nhfAmount += element.nhfAmount;
          }
          return acc;
        }, { totalNetPay: 0, employeeGrossPay: 0, orgPension: 0, paye: 0, employeePension: 0, nhfAmount: 0 });


        this.totalNetPay = total.totalNetPay;
        this.employeeGrossPay = total.employeeGrossPay;
        this.orgPension = total.orgPension;
        this.paye = total.paye;
        this.employeePension = total.employeePension;
        this.nhfAmount = total.nhfAmount;

        if (this.currentPayrunData?.grossPay && this.currentPayrunData?.netPay && this.currentPayrunData?.empCount) {
          total.totalNetPay += this.currentPayrunData.netPay;
          total.employeeGrossPay += this.currentPayrunData.grossPay;
          this.trendEmployees += this.currentPayrunData.empCount
        }
        // Update current payrun data
        this.payrunTrend.currentPayrun.grossPay = total.employeeGrossPay;
        this.payrunTrend.currentPayrun.netPay = total.totalNetPay;
        this.payrunTrend.currentPayrun.empCount = this.trendEmployees;

        // Calculate variance for grossPay, netPay, and empCount
        const { previousPayrun, currentPayrun, variance } = this.payrunTrend;
        variance.grossPay = currentPayrun.grossPay - previousPayrun.grossPay;
        variance.netPay = currentPayrun.netPay - previousPayrun.netPay;
        variance.empCount = currentPayrun.empCount - previousPayrun.empCount;

        // Determine trend direction for each field
        this.growthTrend = this.calculateTrend(variance);

      },
  
  // Helper function to calculate trend direction
      calculateTrend(variance) {
        return {
          grossPay: variance.grossPay > 0 ? 'upward' : variance.grossPay < 0 ? 'downward' : 'no change',
          netPay: variance.netPay > 0 ? 'upward' : variance.netPay < 0 ? 'downward' : 'no change',
          empCount: variance.empCount > 0 ? 'upward' : variance.empCount < 0 ? 'downward' : 'no change'
        };
      },
  
  
      handleCloseTotalGross(){
        this.isTotalGross = false
      },
      handleClose() {
        this.getEmployees();
        this.openModal = false;
        this.modalType = "";
      },
      handleEditClose() {
        this.getEmployees();
        this.openEditModal = false;
        this.modalType = "";
      },
      handleEdit(type, id) {
        this.modalType = type;
        this.userId = id;
        this.openEditModal = true;
      },
      handleClick(type, id) {
        this.modalType = type;
        this.userId = id;
        this.openModal = true;
      },
      handleProcessAllEmployees() {},
      handleItemsPerPage(itemNo) {
        this.itemsPerPage = itemNo;
        this.getEmployees();
      },
      handlePage(pageNumber) {
        this.numberOfPage = pageNumber;
        this.getEmployees();
      },
      buttonOptionSelected(option) {
        if (option === "Save as Draft") {
          this.save("Drafts");
        } else {
          this.$router.push({ name: "Pay Runs" });
        }
      },
      handleModal() {
        this.setModal = true;
      },
      invalid_permission() {
        this.$store.commit("SET_TOAST", {
          text: "You Do not have the required permission",
          color: "error"
        });
      },
    save(value) {
        this.disableBtn = true;
        if (this.checkboxes.length) {
            const currentDate = new Date();
            const getPayrun = {
                employeeIds: []
            };
            getPayrun.createdBy = this.$AuthUser.id;
            getPayrun.orgId = this.$AuthUser.orgId;
            getPayrun.payType = "salaried";
            getPayrun.payFrequency = "monthly";
            getPayrun.month = this.$route.query.month;
            getPayrun.year = this.$route.query.year;
            getPayrun.payOffCycleId = this.payOffCycleId;
            getPayrun.payStartDay = this.payStartDay;
            getPayrun.payDate = new Intl.DateTimeFormat('en-CA').format(currentDate);
            getPayrun.paymentType = this.nameOnPayslip;
            getPayrun.type = this.type;

            this.checkboxes.map(element => {
                if (element.reimbursements) {
                    parseFloat(element.reimbursements);
                }
                getPayrun.employeeIds.push(element.employeeId);
            });

            if (value === "Drafts") {
                getPayrun.payrollStatus = "draft";
            } else {
                getPayrun.payrollStatus = "review";
            }

            this.$_initialOffCyclePay(getPayrun)
            .then(result => {
                this.disableBtn = false;
                this.$toasted.success("Pay run created successfully", {
                duration: 5000
                });
                this.$router.push({
                name: "Pay Runs",
                query: { currentTab: value }
                });
                return result;
            })
            .catch(err => {
                this.disableBtn = false;
                this.$toasted.error(
                "An error occured, Please contact an IT personnel",
                { duration: 5000 }
                );
                return err;
            });
        } else {
            this.$toasted.error(
            "No employee selected, Please select an employee to pay",
            { duration: 3000 }
            );
            this.disableBtn = false;
        }
    },
    checkReimbursement(value) {
        const { id } = value;
        let amount = parseFloat(value.value);
        const hasNetPay = this.netPay.find(element => element.id === id);
        if (hasNetPay) {
          const data = this.tableData.find(item => item.id === id);
          if (!amount) amount = 0;
          const initialNetPayObject = lodash(hasNetPay);
  
          initialNetPayObject.netPay += amount;
          data.netPay = initialNetPayObject.netPay;
        }
      },
      activateSelection(value, date) {
        const ObjDate = JSON.parse(date);
  
        this.payCycleMonth = ObjDate.month;
        this.payCycleYear = ObjDate.year;
        this.employees = {};
        if (value === "Regular") {
          this.loading = true;
          this.getEmployees(
            `employees/?type=${value}&month=${ObjDate.month}&year=${ObjDate.year}`
          );
        } else if (value === "Cycle") {
          this.loading = true;
          if (this.selectedPaymentType === "regularPay") {
            this.getEmployees(
              `employees/?paymentType=${this.selectedPaymentType}&month=${ObjDate.month}&year=${ObjDate.year}`
            );
          } else {
            this.getEmployees(
              `employees/?paymentType=${this.selectedPaymentType}`
            );
          }
        }
      },
      handleSearch(value) {
        this.employeeSearchInput = value;
        this.getEmployees(value)
      },
      makeSearch(value) {
        if (this.payrunType !== "Terminal") {
          this.item.map(employee => {
            if (employee.id === value) {
              this.tableData.push(employee);
            }
          });
        } else {
          this.employees = this.item.find(employee => employee.id === value);
        }
      },
      submit(){
        this.setModal = false;
      },
      resetRunby(value) {
        this.employees = {};
        if (value === "Regular") {
          this.loading = true;
          this.getEmployees(
            `employees/?type=${value}&month=${this.payCycleMonth}&year=${this.payCycleYear}`
          );
        } else if (value === "Off Cycle") {
          this.loading = true;
          if (this.selectedPaymentType === "regularPay") {
            this.getEmployees(
              `employees/?paymentType=${this.selectedPaymentType}&month=${this.payCycleMonth}&year=${this.payCycleYear}`
            );
          } else {
            this.getEmployees(
              `employees/?paymentType=${this.selectedPaymentType}`
            );
          }
        }
      },
      checkButtonChange(value) {
        // this.loadingRunBy = true;
        // this.loading = true;
        this.selected = [];
        this.radioValue = value;
        this.employeeSearch = false;
        if (value !== "employee") {
          this.getEmployees(
            `employees/?type=${value}&month=${this.payCycleMonth}&year=${this.payCycleYear}`
          );
        }
  
        // const allSelected = { name: "All", id: "0" };
        if (value === "all") {
          this.runByCheckbox = [];
          this.loadingRunBy = false;
        }
        if (value === "location") {
          this.runByCheckbox = this.locationArray;
        }
        if (value === "function") {
          this.runByCheckbox = this.functionArray;
        }
        if (value === "employmentType") {
          this.runByCheckbox = this.employmentType;
          // this.runByCheckbox.unshift(allSelected);
          this.loadingRunBy = false;
        }
        if (value === "level") {
          this.runByCheckbox = this.levelArray;
        }
        if (value === "employee") {
          this.tableData = [];
          this.employeeSearch = true;
          this.loadingRunBy = false;
          this.loading = false;
        }
      },
      reload() {
        this.getEmployees();
      },
      getEmployees(searchParams) {
        this.tableLoading = true;
        const { month, year, ...payload } = this.$route.query;
        this.type = payload.type;
        
        const offCycleId = payload.offCycleId;
        const orgId = this.$orgId
        this.nameOnPayslip = payload.nameOnPayslip
        this.payOffCycleId = offCycleId,
        this.payStartDay = payload.payStartDay;

        const pageNumber = !searchParams ? this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${Number(this.metaData.page)}` : '';
        const itemPage = !searchParams ? this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "" : '';
        const payType = payload.payType ? `payType=${payload.payType}` : "";
        const payFrequency = payload.payFrequency
          ? `&payFrequency=${payload.payFrequency}`
          : "";
        const designationId = payload.designationId
          ? `&designationId=${payload.designationId}`
          : "";
        const locationId = payload.locationId
          ? `&locationId=${payload.locationId}`
          : "";
        const functionId = payload.functionId
        ? `&functionId=${payload.functionId}`
        : "";
        const levelId = payload.levelId ? `&levelId=${payload.levelId}` : "";
        const employeeId = payload.employeeId
          ? `&employeeId=${payload.employeeId}`
          : "";
        const splitPosition = payload.splitPosition
          ? `&splitPosition=${payload.splitPosition}`
          : ``;
        const employmentType = payload.employmentType
          ? `&employmentType=${payload.employmentType}`
          : ``;
        const search = searchParams ? `&search=${searchParams}` : ``;
        const formatPayload = `${payType}${payFrequency}${designationId}${locationId}${functionId}${levelId}${employeeId}${splitPosition}${pageNumber}${itemPage}${employmentType}${search}`;
        this.$_getOffCyclePayRegister({orgId, offCycleId, month, year}, formatPayload)
        .then(response => {
          
            const {
              approvedBy,
              paidBy,
              payDate,
              payType: pay,
              payFrequency: frequency,
              splitPosition: split,
              successRate,
              totalEmployees,
              payrunTrend,
              initiatedBy,
              type
            } = response.data;
            this.payDate = payDate
            this.payType = pay;
            this.metaData = response.data.meta;
            this.payFrequency = frequency;
            this.splitPosition = split;
            this.totalEmployees = totalEmployees;
            this.payrunTrend = payrunTrend;
            this.payrunType = type;
            this.initiatedBy = initiatedBy;
            this.approvedBy = approvedBy;
            this.paidBy = paidBy;
            this.successRate = successRate;
            this.employeeAddition = response.data.employees;
            this.netPay = lodash(response.data.employees);
            this.tableData = response.data.employees;
            this.metaData = response.data.meta;
            const {currentPayrun} = this.payrunTrend
            if(currentPayrun.grossPay > 0 && currentPayrun.netPay > 0 && currentPayrun.empCount > 0){
              this.currentPayrunData.grossPay = currentPayrun.grossPay
              this.currentPayrunData.netPay = currentPayrun.netPay
              this.currentPayrunData.empCount = currentPayrun.empCount
            }
            if (this.tableData.length === 0) {
              this.$router.push({ name: "Pay Runs" }).then(() => {
                this.$toasted.error(
                  "No employee(s) found with such Pay frequency",
                  { duration: 9000 }
                );
                this.tableLoading = false;
              });
            } else {
              this.additionalItems =
              response.data.employees.monthlyAdditionsPayItems;
              this.item = response.data.employees.map(item => ({
                name: `${item.fname} ${item.lname}`,
                ...item
              }));
              this.loading = false;
              this.tableLoading = false;
            }
        })
        .catch(error => {
            this.$toasted.error(
                `${error.message} please contact your administrator`,
                { duration: 5000 }
            );
        });
      },
      getOnePayrun() {
        this.$_getOnePayrun(this.$route.params.id).then(result => {
          this.netPay = lodash(result.data.payrun.employees);
          this.tableData = result.data.payrun.employees;
          this.payCycleMonth = result.data.payrun.month;
          this.isPresent = true;
          this.loading = false;
        });
      },
      resetModal() {
        this.setModal = false;
      }
    },
    beforeMount() {
      this.getEmployees();
    }
  };
  </script>
  
  <style scoped>
  .buttons {
    width: 126px;
    height: 44px;
    border-radius: 5px;
  }
  .sideDot1 {
    width: 7px;
    height: 7px;
    background-color: #46aa80;
  }
  .sideDot2 {
    width: 7px;
    height: 7px;
    background-color: #c3c2c4;
  }
  .grosspay {
    background: #2176ff26;
    padding: 0.3rem 0.7rem;
    color: #2176ff;
    border-radius: 5px;
  }
  
  .w-204{
    flex: 1 1 calc(100% / 7) !important;
  }
  .gridContainer {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); /* Responsive columns */
      gap: 1rem; /* Gap between the grid items */
  }
  </style>
  